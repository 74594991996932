import { render, staticRenderFns } from "./goWorlOrder.vue?vue&type=template&id=b81e3c28&scoped=true"
import script from "./goWorlOrder.vue?vue&type=script&lang=js"
export * from "./goWorlOrder.vue?vue&type=script&lang=js"
import style0 from "./goWorlOrder.vue?vue&type=style&index=0&id=b81e3c28&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b81e3c28",
  null
  
)

component.options.__file = "goWorlOrder.vue"
export default component.exports