<template>
  <div class="box">
    <div class="route">
      <div>
        <i class="el-icon-arrow-left icon" @click="$router.back()"></i>
        <span class="title">新建工单</span>
      </div>
      <div>
        <el-button type="primary" @click="submit">保 存</el-button>
      </div>
    </div>
    <div>
      <div class="item">工单信息</div>
      <div class="from">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="115px"
          class="demo-ruleForm"
        >
          <el-form-item label="工单类型" prop="radio" required>
            <div>
              <el-radio v-model="ruleForm.radio" label="1" border
                >消缺</el-radio
              >
              <el-radio v-model="ruleForm.radio" label="2" border disabled
                >清洗</el-radio
              >
              <el-radio v-model="ruleForm.radio" label="3" border disabled
                >检修</el-radio
              >
              <el-radio v-model="ruleForm.radio" label="4" border disabled
                >抢修</el-radio
              >
              <el-radio v-model="ruleForm.radio" label="5" border disabled
                >其他</el-radio
              >
            </div>
          </el-form-item>
          <el-form-item label-width="0">
            <div class="flex">
              <el-form-item
                label="计划开始时间"
                prop="startTime"
                label-width="115px"
                required
              >
                <el-date-picker
                  v-model="ruleForm.startTime"
                  type="date"
                  placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                label="计划完成时间"
                label-width="120px"
                style="margin-left: 30px"
                required
                prop="endTime"
              >
                <el-date-picker
                  v-model="ruleForm.endTime"
                  type="date"
                  placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                label="工单级别"
                label-width="120px"
                style="margin-left: 30px"
                required
              >
                <el-select v-model="ruleForm.level" placeholder="请选择">
                  <el-option label="正常状态" value="0"></el-option>
                  <el-option label="紧急状态" value="1"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form-item>

          <el-form-item label="工单内容" prop="textarea" required>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="ruleForm.textarea"
            >
            </el-input>
          </el-form-item>

          <el-form-item label="工单流程名称" prop="workOrderNo">
            <el-select
              v-model="ruleForm.workOrderName"
              placeholder="请选择工单流"
              @change="changeWork"
            >
              <el-option
                v-for="item in workOrderList"
                :key="item.id"
                :label="item.workOrderName"
                :value="{
                  workOrderNo: item.workOrderNo,
                  workOrderName: item.workOrderName,
                }"
              >
              </el-option>
            </el-select>
            <!-- :value="{
                  workOrderNo: item.workOrderNo,
                  workOrderName: item.workOrderName,
                }" -->
          </el-form-item>
          <!-- <el-form-item label="工单流程名称" label-width="120px" required>
            <el-select v-model="workOrderName" placeholder="请选择工单流">
              <el-option
                v-for="item in workOrderList"
                :key="item.id"
                :label="item.workOrderName"
                :value="{
                  workOrderNo: item.workOrderNo,
                  workOrderName: item.workOrderName,
                }"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
<!--
          <el-form-item label="附件">
            <el-upload
              class="avatar-uploader"
              :action="$uploadURL"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img
                v-if="ruleForm.imageUrl"
                :src="ruleForm.imageUrl"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item> -->
        </el-form>
      </div>
    </div>

    <!-- <div class="item">处理信息</div> -->
    <!-- <div class="from">
      <el-form
        :model="ruleForm2"
        :rules="rules"
        ref="ruleForm"
        label-width="115px"
        class="demo-ruleForm"
      >
        <el-form-item label-width="0">
          <div class="flex">
            <el-form-item label="处理结果" label-width="120px" required>
              <el-select v-model="ruleForm2.level" placeholder="请选择">

              </el-select>
            </el-form-item>

            <el-form-item
              label="当前处理人"
              label-width="120px"
              style="margin-left: 30px"
              required
            >
              <el-select v-model="ruleForm2.level" placeholder="请选择">

              </el-select>
            </el-form-item>
          </div>
        </el-form-item>

        <el-form-item label="相关图片">
          <el-upload
            class="avatar-uploader"
            :action="$uploadURL"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
          >
            <img
              v-if="ruleForm2.imageUrl"
              :src="ruleForm2.imageUrl"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="处理报告" required>
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            v-model="ruleForm.textarea"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div> -->

    <div>
      <div class="item">任务信息</div>
      <div style="margin-left: 100px; margin-top: 20px">
        <el-table :data="tableData" border style="width: 83%">
          <el-table-column
            type="index"
            label="序号"
            width="300px"
            align="center"
          ></el-table-column>
          <el-table-column
            label="厂站名称"
            show-overflow-tooltip
            align="center"
            width="140px"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.powerStationName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="告警名称" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.signalName }}</span>
            </template>
          </el-table-column>

          <el-table-column label="告警类型" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.signalTypeName }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        radio: "1",
        startTime: "",
        endTime: "",
        level: "",
        textarea: "",
        imageUrl: "",
        workOrderNo: "",
        workOrderName: "",
      },

      rules: {
        startTime: [
          { required: true, message: "请选择开始时间", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "请选择完成时间", trigger: "blur" },
        ],
        textarea: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
      },
      tableData: [],
      workOrderList: [],
    };
  },
  created() {
    this.getQueryOne();
    this.getworkOrderNo();
  },
  methods: {
    handleAvatarSuccess(res) {
      this.ruleForm.imageUrl = res.data.url;
      // console.log(res.data.url,117);
    },
    // 获取表格数据
    async getQueryOne() {
      const res = await this.$http.post("deviceAlarmRecord/queryOne", {
        id: this.$route.query.id,
      });
      if (res.data.code === 200) {
        this.tableData = [res.data.data];
      } else {
        this.$message.error(res.data.message);
      }
    },
    // 获取消缺
    async getworkOrderNo() {
      let data = {
        // condition: {
        //   powerStationCode: localStorage.getItem("powerStationCode"),
        //   workOrderType: 1,
        // },
        currPage: 1,
        pageSize: 100000,
      };

      const res = await this.$http.post("WorkOrder/workList", data);
      console.log(res, 267);
      if (res.data.code === 200) {
        this.workOrderList = res.data.data.data;
      }
    },
    // 提交
    submit() {
      this.$refs.ruleForm.validate(async (isOk) => {
        if (isOk) {
          console.log(this.workOrderList[0], 275);
          let data = {
            createCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
            createName: JSON.parse(localStorage.getItem("userInfo")).name,
            jsonData: {
              tableData: this.tableData,
            },
            workOrderName: this.ruleForm.workOrderName,
            workOrderNo: this.ruleForm.workOrderNo,
            workOrderOverTime: "",
            workOrderType: 1,
            orderLevel: this.ruleForm.level,
            workOrderContent: this.ruleForm.textarea,
            planStartTime: this.ruleForm.startTime,
            planEndTime: this.ruleForm.endTime,
            powerStationCode:localStorage.getItem("powerStationCode")
          };
          const res = await this.$http.post(
            "WorkOrder/createWorkOrderApply",
            data
          );
          if (res.data.code === 200) {
            this.$message.success("提交成功");
            this.$router.push("/yunwei/yunwei/workOrderManage");
          } else {
            this.$message.error(res.data);
          }
        }
      });
    },
    changeWork(val) {
      this.ruleForm.workOrderName = val.workOrderName;
      this.ruleForm.workOrderNo = val.workOrderNo;
    },
  },
};
</script>

<style lang="scss" scoped>
// @import url("./table.css");
.box {
  min-height: 100vh;
  margin: 20px;
  padding: 20px;
  color: #fff;
  font-size: 18px;
  background-color: #21527e;

  .route {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 0;
    box-sizing: border-box;
  }

  .icon {
    font-size: 24px;
    margin-right: 10px;
  }

  .item {
    width: 300px;
    position: relative;
    margin-left: 30px;
    margin-top: 20px;
  }

  .item::before {
    content: "";
    border-left: 5px solid #02a7f0; /* 设置竖线样式 */
    position: absolute;
    left: -15px; /* 调整竖线位置，根据需要调整 */
    height: 100%; /* 让竖线高度与.item高度相同 */
  }
}

.from {
  width: 80%;
  margin: 20px 0 20px 50px;

  .flex {
    display: flex;
    // justify-content: space-between;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #fff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px solid #8c939d;
  border-radius: 5px;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.title {
  font-size: 20px;
}

::v-deep .el-radio.is-bordered + .el-radio.is-bordered {
  margin-left: -15px;
}
::v-deep .el-form-item--small .el-form-item__label {
  color: #fff;
}
::v-deep .el-radio--small.is-bordered .el-radio__label {
  color: #fff !important;
}

/deep/ .el-table--border,
.el-table--group {
  border: 1px solid rgba(151, 179, 203, 0.55);
}

/deep/ .el-table--border th {
  background-color: #21527e;

  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}

/deep/ .el-table th {
  background-color: #2d5981;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}

/deep/ .el-table td {
  background-color: #2d5981;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}

/deep/ .el-table__body-wrapper {
  background: #2d5981;
}

/deep/ .el-table__body tr.hover-row > td {
  background-color: transparent !important;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f59a23 !important;
}

  /deep/ .el-form-item__label {
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #fff;
  font-size: 13px;
  width: 150px;
}
/deep/ .el-input__inner {
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #fff;
  background: #21527e;
  font-size: 13px;
  border: 1px solid rgba(242, 242, 242, 0.57);
}
/deep/ .el-textarea__inner {
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #fff;
  background: #21527e;
  font-size: 13px;
  border: 1px solid rgba(242, 242, 242, 0.57);
}
/deep/ .el-upload--picture-card {
  background: #21527e;
  height: 120px;
  width: 120px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  background: #21527e;
  height: 120px;
  width: 120px;
}

</style>
